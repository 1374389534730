/*
  Objects encapsulating key-function pairs for
  effectless API calls, i.e. get
*/

import axios from '../lib/axios';

function parseResponse(response) {
  if (response.data.content != null) {
    return response.data.content;
  } else if (response.data.errors != null) {
    return response.data.errors[0];
  } else {
    return response;
  }
}

/* START Queries for the System & Entity-Based Statistics */

export const getSystemStatistics = {
  key: 'system-statistics',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/system/statistics/${suffix}`);
    return parseResponse(response);
  }
};

export const getDistinctiveStatistics = {
  key: 'distinctive-statistics',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/system/distinctive_statistics/${suffix}`);
    return parseResponse(response);
  }
};

export const getThemeOptions = {
  key: 'theme-options',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/companies/theme_options/`);
    return parseResponse(response);
  }
};

export const getCompanyDetailStatistics = (companyId) => {
  return {
    key: ['company-detail-statistics', companyId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/companies/${companyId}/statistics/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getApplicationDetailStatistics = (applicationId) => {
  return {
    key: ['application-detail-statistics', applicationId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/applications/${applicationId}/statistics/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceDetailStatistics = (deviceId) => {
  return {
    key: ['device-detail-statistics', deviceId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/devices/${deviceId}/statistics/${suffix}`);
      return parseResponse(response);
    }
  }
};

/* END Queries for the System & Entity-Based Statistics */

export const getDeviceList = {
  key: 'device-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/devices/${suffix}`);
    return parseResponse(response);
  }
};

export const getIntervenedApplicationList = {
  key: 'intervened-application-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/device-applications/intervened_applications/${suffix}`);
    return parseResponse(response);
  }
};

export const getSearchFile  = {
  key: 'search-file',
  func: async (queryParams, bodyParams=null) => {
    const tz_offset =  new Date().getTimezoneOffset();
    const suffix = queryParams ? `?tz_offset=${tz_offset}&${queryParams}` : `?tz_offset=${tz_offset}`;
    const response = await axios.get(`/manager/devices/download_search/${suffix}` ,{
      responseType: 'blob'});
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
    }
};

export const getDeviceDetail = {
  key: 'device-detail',
  func: async (deviceFiscalId) => {
    const response = await axios.get(`/manager/devices/${deviceFiscalId}/`);
    return parseResponse(response);
  }
};

export const getDeviceAssignedApplications = (deviceFiscalId) => {
  return {
    key: 'device-assigned-applications',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?device__fiscal_id=${deviceFiscalId}&${queryParams}`
        : `?device__fiscal_id=${deviceFiscalId}`;
      const response = await axios.get(`/manager/device-applications/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceAssignableApplications = (deviceFiscalId) => {
  return {
    key: ['device-assignable-applications', deviceFiscalId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?applications_assignable_to_device=${deviceFiscalId}&${queryParams}`
        : `?applications_assignable_to_device=${deviceFiscalId}`;
      const response = await axios.get(`/manager/applications/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceCompatibleVersions = (deviceFiscalId) => {
  return {
    key: ['device-compatible-versions', deviceFiscalId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?versions_assignable_to_device=${deviceFiscalId}&${queryParams}`
        : `?versions_assignable_to_device=${deviceFiscalId}`;
      const response = await axios.get(`/manager/versions/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getApplicationList = {
  key: 'application-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/applications/${suffix}`);
    return parseResponse(response);
  }
};

export const getApplicationDetail = {
  key: 'application-detail',
  func: async (appId) => {
    const response = await axios.get(`/manager/applications/${appId}/`);
    return parseResponse(response);
  }
};

export const getVersionDetail = {
  key: 'version-detail',
  func: async (versionId) => {
    const response = await axios.get(`/manager/versions/${versionId}/`);
    return parseResponse(response);
  }
};

export const getApplicationVersions = (appId) => {
  return {
    key: ['application-versions', appId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?application=${appId}&${queryParams}` : `?application=${appId}`;
      const response = await axios.get(`/manager/versions/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getVersionFile = {
  key: 'version-download',
  func: async (appId, versionId) => {
    const response = await axios.get(`/manager/versions/${versionId}/download_version/`, {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
};

export const getBulkDeviceCreateTemplate = {
  key: 'bulk-device-create-template',
  func: async () => {
    const response = await axios.get('/manager/devices/download_bulk_create_devices_from_excel_template/', {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
}

export const getBulkDeviceStoreCreateTemplate = {
  key: 'bulk-device-store-create-template',
  func: async () => {
    const response = await axios.get('/manager/companies/download_bulk_create_device_stores_from_excel_template/', {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
}

// This is used via Merchant Upload page.
export const getBulkMerchantCreateTemplate = {
  key: 'bulk-merchant-create-template',
  func: async () => {
    const response = await axios.get('/manager/companies/download_bulk_create_merchants_from_excel_template/', {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
}

export const getApplicationTypes = {
  key: 'application-types',
  func: async () => {
    const response = (await axios.get('/manager/application-types/'));
    return parseResponse(response);
  }
};

export const getDeviceDetailForLocationData = {
  key: 'device-detail-location',
  func: async (deviceFiscalId) => {
    const response = await axios.get(`/manager/devices/${deviceFiscalId}/location/`);
    return parseResponse(response);
  }
};

export const getDeviceTelemetryDetail = {
  key: 'device-telemetry-detail',
  func: async (deviceFiscalId) => {
    const response = await axios.get(`/telemetry/${deviceFiscalId}/`);
    return parseResponse(response);
  }
};

export const getCompanyList = {
  key: 'company-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/companies/${suffix}`);
    return parseResponse(response);
  }
};

export const getMerchantFile = {
  key: 'merchant-download',
  func: async (queryParams) => {
    const tz_offset =  new Date().getTimezoneOffset();
    const suffix = queryParams ? `?tz_offset=${tz_offset}` : `?tz_offset=${tz_offset}`;
    const response = await axios.get(`/manager/companies/download_merchants/${suffix}`, {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
};

export const getCompanyDetail = {
  key: 'company-detail',
  func: async (companyId) => {
    const response = await axios.get(`/manager/companies/${companyId}/`);
    return parseResponse(response);
  }
};

export const getCompanyAccessibleApplications = (companyId) => {
  return {
    key: 'company-accessible-applications',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?applications_assignable_to_company=${companyId}&${queryParams}`
        : `?applications_assignable_to_company=${companyId}`;
      const response = await axios.get(`/manager/applications/${suffix}`);
      return parseResponse(response);
    }
  };
};

export const getApplicationAssignableDevices = (applicationId, versionId) => {
  return {
    key: 'application-assignable-devices',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
          ? `?devices_assignable_to_application=${applicationId}&version_id=${versionId}&${queryParams} `
          : `?devices_assignable_to_application=${applicationId}&version_id=${versionId}`;
      const response = await axios.get(`/manager/devices/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getCompanyAccessibleRepositories = (companyId) => {
  return {
    key: 'company-accessible-repositories',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?repositories_assignable_to_company=${companyId}&${queryParams}`
        : `?repositories_assignable_to_company=${companyId}`;
      const response = await axios.get(`/manager/repositories/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryList = {
  key: 'repository-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/repositories/${suffix}`);
    return parseResponse(response);
  }
};

export const getRepositoryDetail = {
  key: 'repository-detail',
  func: async (repositoryId) => {
    const response = await axios.get(`/manager/repositories/${repositoryId}/`);
    return parseResponse(response);
  }
};

export const getRepositoryApplications = (repositoryId) => {
  return {
    key: ['repository-applications', repositoryId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?repository=${repositoryId}&${queryParams}` : `?repository=${repositoryId}`;
      const response = await axios.get(`/manager/repository-applications/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryDevices = (repositoryId) => {
  return {
    key: ['repository-devices', repositoryId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?repository=${repositoryId}&${queryParams}` : `?repository=${repositoryId}`;
      const response = await axios.get(`/manager/repository-devices/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryAccesses = (repositoryId) => {
  return {
    key: ['repository-accesses', repositoryId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?repository=${repositoryId}&${queryParams}` : `?repository=${repositoryId}`;
      const response = await axios.get(`/manager/repository-accesses/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryAssignableApplications = (repositoryId) => {
  return {
    key: 'repository-assignable-applications',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?applications_assignable_to_repository=${repositoryId}&${queryParams}`
        : `?applications_assignable_to_repository=${repositoryId}`;
      const response = await axios.get(`/manager/applications/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryAssignableDevices = (repositoryId) => {
  return {
    key: 'repository-assignable-devices',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams
        ? `?devices_assignable_to_repository=${repositoryId}&${queryParams}`
        : `?devices_assignable_to_repository=${repositoryId}`;
      const response = await axios.get(`/manager/devices/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRepositoryAssignableCompanies = (repositoryId) => {
  return {
    key: 'repository-assignable-companies',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?companies_assignable_to_repository=${repositoryId}&${queryParams}` : `?companies_assignable_to_repository=${repositoryId}`;
      const response = await axios.get(`/manager/companies/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getUserProfileDetail = {
  key: 'user-profile-detail',
  func: async (userId) => {
    const response = await axios.get(`/manager/users/${userId}/`);
    return parseResponse(response);
  }
};

export const getUserOTPInfo = {
  key: 'user-otp-info',
  func: async (userId) => {
    const response = await axios.get(`/auth/otp/retrieve/${userId}/`);
    return parseResponse(response);
  }
};

export const getServiceMasterOTP = {
  key: 'service-master-otp',
  func: async () => {
    const response = await axios.get(`/auth/otp/service-master-otp/`);
    return parseResponse(response);
  }
};

export const getRoleList = {
  key: 'role-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/groups/${suffix}`);
    return parseResponse(response);
  }
};

export const getRoleDetail = {
  key: 'role-detail',
  func: async (groupId) => {
    const response = await axios.get(`/manager/groups/${groupId}/`);
    return parseResponse(response);
  }
};

export const getRoleUsers = (groupId) => {
  return {
    key: 'role-users',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : '';
      const response = await axios.get(`/manager/groups/${groupId}/users/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getRolePermissions = (groupId) => {
  return {
    key: 'role-permissions',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : '';
      const response = await axios.get(`/manager/groups/${groupId}/permissions/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getPermissionList = {
  key: 'permission-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/groups/all_permissions/${suffix}`);
    return parseResponse(response);
  }
};

export const getUserList = {
  key: 'user-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/users/${suffix}`);
    return parseResponse(response);
  }
};

export const getResponsiblePersonList = {
  key: 'user-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/users/responsible_people/${suffix}`);
    return parseResponse(response);
  }
};

export const getDeviceRepositories = (deviceFiscalId) => {
  return {
    key: ['device-repositories', deviceFiscalId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?device__fiscal_id=${deviceFiscalId}&${queryParams}` : `?device__fiscal_id=${deviceFiscalId}`;
      const response = await axios.get(`/manager/repository-devices/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getTagList = {
  key: 'tag-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/tags/${suffix}`);
    return parseResponse(response);
  }
};

export const getTagDetail = {
  key: 'tag-detail',
  func: async (tagId) => {
    const response = await axios.get(`/manager/tags/${tagId}/`);
    return parseResponse(response);
  }
};

export const getDeviceTagsByDevice = (deviceFiscalId) => {
  return {
    key: ['device-tags-by-device', deviceFiscalId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?device__fiscal_id=${deviceFiscalId}&${queryParams}` : `?device__fiscal_id=${deviceFiscalId}`;
      const response = await axios.get(`/manager/device-tags/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceTagsByTag = (tagId) => {
  return {
    key: ['device-tags-by-tag', tagId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?tag=${tagId}&${queryParams}` : `?tag=${tagId}`;
      const response = await axios.get(`/manager/device-tags/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getMerchantList = {
  key: 'merchant-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/merchants/${suffix}`);
    return parseResponse(response);
  }
};

export const getMerchantDetail = {
  key: 'merchant-detail',
  func: async (merchantId) => {
    const response = await axios.get(`/manager/merchants/${merchantId}/`);
    return parseResponse(response);
  }
};

export const getMerchantDevices = (merchantId) => {
  return {
    key: ['device-list', merchantId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?merchant=${merchantId}&${queryParams}` : `?merchant=${merchantId}`;
      const response = await axios.get(`/manager/devices/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getCompanyMerchants = (companyId) => {
  return {
    key: ['merchant-list', companyId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?company=${companyId}&${queryParams}` : `?company=${companyId}`;
      const response = await axios.get(`/manager/merchants/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getApplicationAccesses = (applicationId) => {
  return {
    key: ['application-accesses', applicationId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?application=${applicationId}&${queryParams}` : `?application=${applicationId}`;
      const response = await axios.get(`/manager/application-accesses/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getApplicationAssignableCompanies = (applicationId) => {
  return {
    key: 'application-assignable-companies',
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?companies_assignable_to_application=${applicationId}&${queryParams}` : `?companies_assignable_to_application=${applicationId}`;
      const response = await axios.get(`/manager/companies/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getParameterList = {
  key: 'parameter-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/parameters/${suffix}`);
    return parseResponse(response);
  }
};

export const getParameterDetail = {
  key: 'parameter-detail',
  func: async (parameterId) => {
    const response = await axios.get(`/manager/parameters/${parameterId}/`);
    return parseResponse(response);
  }
};

export const getParameterDataTypeList = {
  key: 'parameter-data-type-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/parameters/parameter_data_types/${suffix}`);
    return parseResponse(response);
  }
};

export const getParameterDevices = (parameterId) => {
  return {
    key: ['parameter-devices', parameterId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?parameter=${parameterId}&${queryParams}` : `?parameter=${parameterId}`;
      const response = await axios.get(`/manager/device-parameters/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceParameters = (deviceFiscalId) => {
  return {
    key: ['device-parameters', deviceFiscalId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?device__fiscal_id=${deviceFiscalId}&${queryParams}` : `?device__fiscal_id=${deviceFiscalId}`;
      const response = await axios.get(`/manager/device-parameters/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceStatusList = {
  key: 'device-status-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/devices/device_states/${suffix}`);
    return parseResponse(response);
  }
};

export const getGSMOperatorList = {
  key: 'gsm-operator-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/devices/gsm_operators/${suffix}`);
    return parseResponse(response);
  }
};

export const getSimStockListForDevice = {
  key: 'sim-stock-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/devices/sim_stocks/${suffix}`);
    return parseResponse(response);
  }
};

export const getModelTypeList = {
  key: 'model-type-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/model-types/${suffix}`);
    return parseResponse(response);
  }
};

export const getModelTypeDetail = {
  key: 'model-type-detail',
  func: async (modelTypeId) => {
    const response = await axios.get(`/manager/model-types/${modelTypeId}/`);
    return parseResponse(response);
  }
};


export const getIncidentList = {
  key: 'incident-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/incidents/${suffix}`);
    return parseResponse(response);
  }
};

export const getIncidentDetail = {
  key: 'incident-detail',
  func: async (incidentId) => {
    const response = await axios.get(`/manager/incidents/${incidentId}/`);
    return parseResponse(response);
  }
};

export const getSLAReport = {
  key: 'sla-report',
  func: async (incidentId) => {
    const response = await axios.get(`/manager/incidents/sla_report/`);
    return parseResponse(response);
  }
};

export const getInvoicingReport = {
  key: 'invoicing-report',
  func: async (queryParams, bodyParams=null) => {
    let suffix = queryParams ? `?${queryParams}&extra_query_method=invoicing_report` : '?extra_query_method=invoicing_report';
    const response = await axios.get(`/manager/incidents/${suffix}`);
    return parseResponse(response);
  }
};

export const getIncidentDocument = {
  key: 'incident-document-download',
  func: async (incidentId) => {
    const response = await axios.get(`/manager/incidents/${incidentId}/download_document/`, {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
};

export const getIncidentHistoryList = {
  key: 'incident-history',
  func: async (incidentId) => {
    const response = await axios.get(`/manager/incident-history/?incident_id=${incidentId}`);
    return parseResponse(response);
  }
};

export const getIncidentTypeList = {
  key: 'incident-type-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/incident-type/${suffix}`);
    return parseResponse(response);
  }
}

export const getIncidentTypeDetail = {
  key: 'incident-type-detail',
  func: async (incidentTypeId) => {
    const response = await axios.get(`/manager/incident-type/${incidentTypeId}/`);
    return parseResponse(response);
  }
};

export const getIncidentTypeCategoryList = {
  key: 'incident-type-category-list',
  func: async () => {
    const response = await axios.get(`/manager/incident-type-category/`);
    return parseResponse(response);
  }
};


export const getModelTypeParameters = (modelTypeId) => {
  return {
    key: ['model-type-parameters', modelTypeId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?model_type=${modelTypeId}&${queryParams}` : `?model_type=${modelTypeId}`;
      const response = await axios.get(`/manager/model-type-parameters/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getDeviceApplicationLogList = {
  key: 'device-application-log-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/device-application-logs/${suffix}`);
    return parseResponse(response);
  }
};

export const getDeviceParameterLogList = {
  key: 'device-parameter-log-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/device-parameter-logs/${suffix}`);
    return parseResponse(response);
  }
};

export const getUserLogList = {
  key: 'user-log-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/user-logs/${suffix}`);
    return parseResponse(response);
  }
};

export const getSystemVersionList = {
  key: 'system-version-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/system/${suffix}`);
    return parseResponse(response);
  }
};

export const getBankList = {
  key: 'bank-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/banks/${suffix}`);
    return parseResponse(response);
  }
};

export const getBankDetail = {
  key: 'bank-detail',
  func: async (bankId) => {
    const response = await axios.get(`/manager/banks/${bankId}/`);
    return parseResponse(response);
  }
};

export const getBankDevicesList = {
  key: 'bank-devices-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/bank-devices/${suffix}`);
    return parseResponse(response);
  }
};

export const getBankDeviceRegistry = (bankDeviceId) => {
  return {
    key: ['bank-device-registry', bankDeviceId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?bank_device=${bankDeviceId}&${queryParams}` : `?bank_device=${bankDeviceId}`;
      const response = await axios.get(`/manager/bank-device-registries/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getScheduledAppAssignmentsForDeviceWorking = (deviceId) => {
  return {
    key: ['device-scheduled-app-assignments-working', deviceId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/devices/${deviceId}/scheduled_app_assignments/working/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getScheduledAppAssignmentsForDeviceQueued = (deviceId) => {
  return {
    key: ['device-scheduled-app-assignments-queued', deviceId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/devices/${deviceId}/scheduled_app_assignments/queued/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getScheduledAppAssignmentsForCompanyWorking = (companyId) => {
  return {
    key: ['company-scheduled-app-assignments-working', companyId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/companies/${companyId}/scheduled_app_assignments/working/${suffix}`);
      return parseResponse(response);
    }
  }
};

export const getScheduledAppAssignmentsForCompanyQueued = (companyId) => {
  return {
    key: ['company-scheduled-app-assignments-queued', companyId],
    func: async (queryParams, bodyParams=null) => {
      const suffix = queryParams ? `?${queryParams}` : ``;
      const response = await axios.get(`/manager/companies/${companyId}/scheduled_app_assignments/queued/${suffix}`);
      return parseResponse(response);
    }
  }
};


export const getSimStockList = {
  key: 'sim-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/sim-stocks/${suffix}`);
    return parseResponse(response);
  }
}


export const getSimStockDetail = {
  key: 'sim-stock-detail',
  func: async (simStockId) => {
    const response = await axios.get(`/manager/sim-stocks/${simStockId}/`);
    return parseResponse(response);
  }
};

export const getBulkSimCreateTemplate = {
  key: 'bulk-sim-create-template',
  func: async () => {
    const response = await axios.get('/manager/sim-stocks/download_bulk_create_sim_from_excel_template/', {
      responseType: 'blob'
    });
    const blob = new Blob([response.data]);
    const filename = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    return {blob: blob, filename: filename};
  }
}

export const getConsumableList = {
  key: 'consumable-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/consumable/${suffix}`);
    return parseResponse(response);
  }
}


export const getConsumableDetail = {
  key: 'consumable-detail',
  func: async (consumableId) => {
    const response = await axios.get(`/manager/consumable/${consumableId}/`);
    return parseResponse(response);
  }
};


export const getCompanySlaTimeList = {
  key: 'sla-time-list',
  func: async (queryParams, bodyParams=null) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.get(`/manager/company-sla-time/${suffix}`);
    return parseResponse(response);
  }
}

export const getCompanySlaTimeDetail = {
  key: 'company-sla-time-detail',
  func: async (companySlaTimeId) => {
    const response = await axios.get(`/manager/company-sla-time/${companySlaTimeId}/`);
    return parseResponse(response);
  }
};