import {lazy, Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './components/layout/dashboard/DashboardLayout';
import PermissionGate from './permission-management/PermissionGate';
import createGenericError from './hooks/createGenericError';
import NotFound from './pages/404';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen/>}>
    <Component {...props} />
  </Suspense>
);

const PermissionFallback = createGenericError({
  code: 'Unauthorized',
  title: 'This page is forbidden',
  message: 'Access to some pages requires special permissions. If you think you should have access to this page, please contact technical support.'
});

// Browse pages

// Auth pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Logout = Loadable(lazy(() => import('./pages/authentication/Logout')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const PasswordSet = Loadable(lazy(() => import('./pages/authentication/PasswordSet')));

// Dashboard pages
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Device pages
const DeviceList = Loadable(lazy(() => import('./pages/device/DeviceList')));
const ServiceInterventionList = Loadable(lazy(() => import('./pages/device/ServiceInterventionList')));
const DeviceDetail = Loadable(lazy(() => import('./pages/device/DeviceDetail')));
const DeviceUpload = Loadable(lazy(() => import('./pages/device/DeviceUpload')));
const DeviceCreate = Loadable(lazy(() => import('./pages/device/DeviceCreate')));
const DeviceEdit = Loadable(lazy(() => import('./pages/device/DeviceEdit')));

// Location services pages
const DeviceForLocationList = Loadable(lazy(() => import('./pages/location/DeviceList')));
const DeviceDetailForLocation = Loadable(lazy(() => import('./pages/location/DeviceDetail')));

// App pages
const ApplicationList = Loadable(lazy(() => import('./pages/app/ApplicationList')));
const ApplicationCreate = Loadable(lazy(() => import('./pages/app/ApplicationCreate')));
const ApplicationDetail = Loadable(lazy(() => import('./pages/app/ApplicationDetail')));
const ApplicationEdit = Loadable(lazy(() => import('./pages/app/ApplicationEdit')));
const ApplicationAssignment = Loadable(lazy(() => import('./pages/app/ApplicationAssignment')));

// Version pages
const VersionEdit = Loadable(lazy(() => import('./pages/app/VersionEdit')));

// Company pages
const CompanyList = Loadable(lazy(() => import('./pages/company/CompanyList')));
const CompanyDetail = Loadable(lazy(() => import('./pages/company/CompanyDetail')));
const CompanyCreate = Loadable(lazy(() => import('./pages/company/CompanyCreate')));
const CompanyEdit = Loadable(lazy(() => import('./pages/company/CompanyEdit')));

// Repository pages
const RepositoryList = Loadable(lazy(() => import('./pages/repository/RepositoryList')));
const RepositoryDetail = Loadable(lazy(() => import('./pages/repository/RepositoryDetail')));
const RepositoryCreate = Loadable(lazy(() => import('./pages/repository/RepositoryCreate')));
const RepositoryEdit = Loadable(lazy(() => import('./pages/repository/RepositoryEdit')));

// Role pages
const RoleList = Loadable(lazy(() => import('./pages/role/RoleList')));
const RoleDetail = Loadable(lazy(() => import('./pages/role/RoleDetail')));
const RoleCreate = Loadable(lazy(() => import('./pages/role/RoleCreate')));

// Tag pages
const TagList = Loadable(lazy(() => import('./pages/tag/TagList')));
const TagDetail = Loadable(lazy(() => import('./pages/tag/TagDetail')));
const TagCreate = Loadable(lazy(() => import('./pages/tag/TagCreate')));
const TagEdit = Loadable(lazy(() => import('./pages/tag/TagEdit')));

// Merchant Pages
const MerchantList = Loadable(lazy(() => import('./pages/merchant/MerchantList')));
const MerchantDetail = Loadable(lazy(() => import('./pages/merchant/MerchantDetail')));
const MerchantCreate = Loadable(lazy(() => import('./pages/merchant/MerchantCreate')));
const MerchantEdit = Loadable(lazy(() => import('./pages/merchant/MerchantEdit')));
const MerchantUpload = Loadable(lazy(() => import('./pages/merchant/MerchantUpload')));

// User pages
const UserList = Loadable(lazy(() => import('./pages/user/UserList')));
const UserDetail = Loadable(lazy(() => import('./pages/user/UserDetail')));
const UserCreate = Loadable(lazy(() => import('./pages/user/UserCreate')));
const UserProfile = Loadable(lazy(() => import('./pages/user/UserProfile')));

// Parameter Pages
const ParameterList = Loadable(lazy(() => import('./pages/parameter/ParameterList')));
const ParameterDetail = Loadable(lazy(() => import('./pages/parameter/ParameterDetail')));
const ParameterCreate = Loadable(lazy(() => import('./pages/parameter/ParameterCreate')));
const ParameterEdit = Loadable(lazy(() => import('./pages/parameter/ParameterEdit')));

// Model Type Pages
const ModelTypeList = Loadable(lazy(() => import('./pages/modeltype/ModelTypeList')));
const ModelTypeDetail = Loadable(lazy(() => import('./pages/modeltype/ModelTypeDetail')));
const ModelTypeCreate = Loadable(lazy(() => import('./pages/modeltype/ModelTypeCreate')));
const ModelTypeEdit = Loadable(lazy(() => import('./pages/modeltype/ModelTypeEdit')));

// Incident Pages
const IncidentList = Loadable(lazy(() => import('./pages/incident/IncidentList')));
const IncidentReport = Loadable(lazy(() => import('./pages/incident/IncidentReport')));
const IncidentSLAReport = Loadable(lazy(() => import('./pages/incident/IncidentSLAReport')));
const InvoicingReport = Loadable(lazy(() => import('./pages/incident/InvoicingReport')));
const IncidentDetail = Loadable(lazy(() => import('./pages/incident/IncidentDetail')));
const IncidentCreate = Loadable(lazy(() => import('./pages/incident/IncidentCreate')));
const IncidentEdit = Loadable(lazy(() => import('./pages/incident/IncidentEdit')));
const IncidentTypeList = Loadable(lazy(() => import('./pages/incident-type/IncidentTypeList')));
const IncidentTypeCreate = Loadable(lazy(() => import('./pages/incident-type/IncidentTypeCreate')));
const IncidentTypeDetail = Loadable(lazy(() => import('./pages/incident-type/IncidentTypeDetail')));
const IncidentTypeEdit = Loadable(lazy(() => import('./pages/incident-type/IncidentTypeEdit')));


// Log Pages
const DeviceApplicationLogList = Loadable(lazy(() => import('./pages/logs/DeviceApplicationLogList')));
const DeviceParameterLogList = Loadable(lazy(() => import('./pages/logs/DeviceParameterLogList')));
const UserLogList = Loadable(lazy(() => import('./pages/logs/UserLogList')));

// Bank Pages
const BankList = Loadable(lazy(() => import('./pages/bank/BankList')));
const BankCreate = Loadable(lazy(() => import('./pages/bank/BankCreate')));
const BankDetail = Loadable(lazy(() => import('./pages/bank/BankDetail')));
const BankEdit = Loadable(lazy(() => import('./pages/bank/BankEdit')));

// Sim Pages
const SimList = Loadable(lazy(()=> import('./pages/sim/SimList')));
const SimCreate = Loadable(lazy(()=> import('./pages/sim/SimCreate')));
const SimEdit = Loadable(lazy(() => import('./pages/sim/SimEdit')));
const SimUpload = Loadable(lazy(() => import('./pages/sim/SimUpload')));

// CompanySLA Time Pages
const CompanySLAtimeList = Loadable(lazy(()=> import('./pages/companySLAtime/CompanySLAtimeList')));
const CompanySLAtimeCreate = Loadable(lazy(()=> import('./pages/companySLAtime/CompanySLAtimeCreate')));
const CompanySLAtimeEdit = Loadable(lazy(() => import('./pages/companySLAtime/CompanySLAtimeEdit')));

// Bank Device Pages
const BankDeviceList = Loadable(lazy(() => import('./pages/bankdevice/BankDeviceList')));

// Device Store Pages
const DeviceStoreUpload = Loadable(lazy(() => import('./pages/merchant/DeviceStoreUpload')));

const routes = [
  {
    path: '*',
    element: <Navigate to="/404"/>
  },
  {
    path: '/404',
    element: <NotFound/>
  },
  {
    path: '/',
    element: <Navigate to="dashboard"/>
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login/>
          </GuestGuard>
        )
      },
      {
        path: 'logout',
        element: (
          <AuthGuard>
            <Logout/>
          </AuthGuard>
        )
      },
      {
        path: 'password',
        children: [
          {
            path: 'reset',
            element: <PasswordReset/>
          },
          {
            path: 'set',
            element: <PasswordSet/>
          }
        ]
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout/>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview/>
      },
      {
        path: 'devices',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_device'}
              >
                <DeviceList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_device'}
              >
                <DeviceCreate/>
              </PermissionGate>
            )
          },
          {
            path: 'upload',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_device'}
              >
                <DeviceUpload/>
              </PermissionGate>
            )
          },
          {
            path: 'serviceintervention',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.handle_intervention'}
              >
                <ServiceInterventionList/>
              </PermissionGate>
            )
          },
          {
            path: ':deviceFiscalId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_device'}
                  >
                    <DeviceDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_device'}
                  >
                    <DeviceEdit/>
                  </PermissionGate>
                )
              }

            ]

          },
        ]
      },
      {
        path: 'apps',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_application'}
              >
                <ApplicationList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_application'}
              >
                <ApplicationCreate/>
              </PermissionGate>
            )
          },
          {
            path: 'assign',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={[
                  'commonlib.add_deviceapplication',
                  'commonlib.view_application',
                  'commonlib.view_version'
                ]}
              >
                <ApplicationAssignment/>
              </PermissionGate>
            )
          },
          {
            path: ':appId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_application'}
                  >
                    <ApplicationDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_application'}
                  >
                    <ApplicationEdit/>
                  </PermissionGate>
                )
              },
              {
                path: 'versions',
                children: [
                  {
                    path: ':versionId',
                    element: (
                      <PermissionGate
                        fallback={PermissionFallback}
                        permissions={'commonlib.change_version'}
                      >
                        <VersionEdit/>
                      </PermissionGate>
                    )
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'location_services',
        children: [
          {
            path: '',
            element: <DeviceForLocationList/>
          },
          {
            path: ':deviceFiscalId',
            element: <DeviceDetailForLocation/>
          }
        ]
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_company'}
              >
                <CompanyList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_company'}
              >
                <CompanyCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':companyId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_company'}
                  >
                    <CompanyDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_company'}
                  >
                    <CompanyEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'repositories',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_repository'}
              >
                <RepositoryList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_repository'}
              >
                <RepositoryCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':repositoryId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_repository'}
                  >
                    <RepositoryDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_repository'}
                  >
                    <RepositoryEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'roles',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'auth.view_group'}
              >
                <RoleList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'auth.add_group'}
              >
                <RoleCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':roleId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'auth.view_group'}
                  >
                    <RoleDetail/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'tags',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_tag'}
              >
                <TagList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_tag'}
              >
                <TagCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':tagId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_tag'}
                  >
                    <TagDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_tag'}
                  >
                    <TagEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'parameters',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_parameter'}
              >
                <ParameterList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_parameter'}
              >
                <ParameterCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':parameterId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_parameter'}
                  >
                    <ParameterDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_parameter'}
                  >
                    <ParameterEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'modeltypes',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_modeltype'}
              >
                <ModelTypeList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_modeltype'}
              >
                <ModelTypeCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':modelTypeId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_modeltype'}
                  >
                    <ModelTypeDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_modeltype'}
                  >
                    <ModelTypeEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentReport/>
              </PermissionGate>
            )
          },
          {
            path: 'sla-reports',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentSLAReport/>
              </PermissionGate>
            )
          },
          {
            path: 'invoicing-reports',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <InvoicingReport/>
              </PermissionGate>
            )
          },
        ]
      },
      {
        path: 'incidents',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_incident'}
              >
                <IncidentCreate/>
              </PermissionGate>
            )
          },
          {
            path: 'reports',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentReport/>
              </PermissionGate>
            )
          },
          {
            path: 'sla-reports',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentSLAReport/>
              </PermissionGate>
            )
          },
          {
            path: ':incidentId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_incident'}
                  >
                    <IncidentDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_incident'}
                  >
                    <IncidentEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
       {
        path: 'incidenttype',
        children: [
          {
            path: '',
            element: (
                <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_incidenttype'}
                >
                  <IncidentTypeList/>
                </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
                <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.add_incidenttype'}
                >
                  <IncidentTypeCreate/>
                </PermissionGate>
            )
          },
          {
            path: ':incidentTypeId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_incidenttype'}
                  >
                    <IncidentTypeDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_incidenttype'}
                  >
                    <IncidentTypeEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'companySLAtime',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_companyslatime'}
              >
                <CompanySLAtimeList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_companyslatime'}
              >
                <CompanySLAtimeCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':companySlaTimeId',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.change_companyslatime'}
              >
                <CompanySLAtimeEdit/>
              </PermissionGate>
            )
          },
        ]
      },
      {
        path: 'merchants',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_merchant'}
              >
                <MerchantList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_merchant'}
              >
                <MerchantCreate/>
              </PermissionGate>
            )
          },
          {
            path: 'upload',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_merchant'}
              >
                <MerchantUpload/>
              </PermissionGate>
            )
          },
          {
            path: ':merchantId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_merchant'}
                  >
                    <MerchantDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_merchant'}
                  >
                    <MerchantEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'sim',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_simstock'}
              >
                <SimList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_simstock'}
              >
                <SimCreate/>
              </PermissionGate>
            )
          },
          {
            path: 'upload',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_simstock'}
              >
                <SimUpload/>
              </PermissionGate>
            )
          },
          {
            path: ':simStockId',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.change_simstock'}
              >
                <SimEdit/>
              </PermissionGate>
            )
          },
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: 'incident',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_incident'}
              >
                <IncidentReport/>
              </PermissionGate>
            )
          },
        ]
      },
      {
        path: 'logs',
        children: [
          {
            path: 'deviceapplicationlog',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_deviceapplicationlog'}
              >
                <DeviceApplicationLogList/>
              </PermissionGate>
            )
          },
          {
            path: 'deviceparameterlog',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_deviceparameterlog'}
              >
                <DeviceParameterLogList/>
              </PermissionGate>
            )
          },
          {
            path: 'userlog',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_userlog'}
              >
                <UserLogList/>
              </PermissionGate>
            )
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_user'}
              >
                <UserList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_user'}
              >
                <UserCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':userId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_user'}
                  >
                    <UserDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_user'}
                  >
                    <UserProfile/>
                  </PermissionGate>
                )
              },
            ]
          },
        ]
      },
      {
        path: 'banks',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.view_bank'}
              >
                <BankList/>
              </PermissionGate>
            )
          },
          {
            path: 'create',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_bank'}
              >
                <BankCreate/>
              </PermissionGate>
            )
          },
          {
            path: ':bankId',
            children: [
              {
                path: '',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.view_bank'}
                  >
                    <BankDetail/>
                  </PermissionGate>
                )
              },
              {
                path: 'edit',
                element: (
                  <PermissionGate
                    fallback={PermissionFallback}
                    permissions={'commonlib.change_bank'}
                  >
                    <BankEdit/>
                  </PermissionGate>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'bankdevices',
        children: [
          {
            path: '',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={['commonlib.view_bankdevice', 'commonlib.view_bankdeviceregistry']}
              >
                <BankDeviceList/>
              </PermissionGate>
            )
          },
        ]
      },
      {
        path: 'devicestores',
        children: [
          {
            path: 'upload',
            element: (
              <PermissionGate
                fallback={PermissionFallback}
                permissions={'commonlib.add_devicestore'}
              >
                <DeviceStoreUpload/>
              </PermissionGate>
            )
          },
        ]
      },
    ]
  },
];

export default routes;
